import React from "react";
import { Container, styled, Typography } from "@mui/material";
import SiteLayout from "components/layouts/SiteLayout";
import {graphql} from "gatsby";
import SectionTitle from "components/atoms/SectionTitle";

const Text = styled(Typography)`
  margin-bottom: 1rem;
  ul {
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 40px;
  }
  li {
    margin-top: 1rem;
    margin-left: 1rem;
  }
`;

export default function ContraceptionPage() {
  return (
    <SiteLayout>
      <Container>
        <SectionTitle>
          Antykoncepcja hormonalna i awaryjna
        </SectionTitle>
        <Typography variant="h2">
          E-recepty na antykoncepcję hormonalną i awaryjną w DokDok
        </Typography>
        <Text>
          <ol>
            <li>
              Przychodnia DokDok umożliwia otrzymanie e-recepty na antykoncepcję
              hormonalną i awaryjną podczas wizyty online w aplikacji mobilnej
              oraz wizyty stacjonarnej w przychodni.
            </li>
            <li>
              E-recepty na antykoncepcję dostępne są tylko dla pacjentek
              zapisanych do przychodni DokDok.
            </li>
            <li>Zasady wystawiania e-recept na środki antykoncepcyjne:</li>
          </ol>
        </Text>

        <Typography variant="h3" fontWeight="bold" sx={{ mb: 2 }}>
          Decyzję o wystawieniu e-recepty podejmuje lekarz na podstawie wywiadu,
          stanu zdrowia pacjentki oraz przedstawionej dokumentacji medycznej
          pacjentki
        </Typography>

        <Typography variant="h2">Antykoncepcja hormonalna</Typography>
        <Text>
          Czas od ostatniej wizyty pacjentki u ginekologa{" "}
          <b>nie może przekraczać 12 miesięcy.</b>
        </Text>

        <Text>
          Lekarz DokDok może wystawić e-receptę na maksymalnie 3 opakowania leku
        </Text>

        <Text>
          Pacjentka przed konsultacją z lekarzem DokDok{" "}
          <b>powinna przygotować jeden z dokumentów:</b>
        </Text>

        <Text>
          <ul>
            <li>
              zdjęcie/skan poprzedniej recepty lub zrzut ekranu z Internetowego
              Konta Pacjenta(IKP) z wcześniejszymi receptami
            </li>

            <li>
              zdjęcie/skan dokumentacji od ginekologa nie starszej niż 12
              miesięcy
            </li>

            <li>
              zaświadczenie od ginekologa o stałym przyjmowaniu określonego leku
            </li>
          </ul>
        </Text>
        <Text>
          Lekarz DokDok może wystawić e-receptę na maksymalnie 3 opakowania leku
        </Text>

        <Typography variant="h2">Antykoncepcja awaryjna</Typography>
        <Text>
          Antykoncepcja awaryjna stosowana jest w{" "}
          <b>przypadkach nagłych w ciągu 5 dni</b> od stosunku płciowego bez
          zabezpieczenia lub w przypadku, gdy zastosowana metoda antykoncepcji
          zawiodła.
        </Text>

        <Text>
          Lekarz POZ może wypisać e-receptę na antykoncepcję awaryjną bez dowodu
          wcześniejszego przyjmowania/zaordynowania przez lekarza specjalistę.
        </Text>

        <Text>
          Antykoncepcja awaryjna nie powinna być stosowana częściej niż raz w
          miesiącu, z tego względu lekarz POZ może wystawić jednorazowo receptę
          na <b>1 opakowanie</b>.
        </Text>

        <Text>
          <ul>
            <li>
              zdjęcie/skan poprzedniej recepty lub zrzut ekranu z Internetowego
              Konta Pacjenta(IKP) z wcześniejszymi receptami
            </li>

            <li>
              zdjęcie/skan dokumentacji od ginekologa nie starszej niż 12
              miesięcy
            </li>

            <li>
              zaświadczenie od ginekologa o stałym przyjmowaniu określonego leku
            </li>
          </ul>
        </Text>
        <Text sx={{ mb: 4 }}>
          Lekarz DokDok może wystawić e-receptę na maksymalnie 3 opakowania leku
        </Text>
      </Container>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
